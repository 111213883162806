import { Component, Input } from '@angular/core';

// service 

import { AuthService} from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';


@Component({
  selector: 'app-chat-charts-events',
  templateUrl: './chat-charts-events.component.html',
  styleUrls: ['./chat-charts-events.component.scss']
})
export class ChatChartsEventsComponent {

@Input() childEvent: string;
@Input() childEventScore: string;


iframes: [];
widget = [];

companyName: string;

eventScore: string; 

dynamicUrl: string;





constructor( public auth: AuthService, public yukkApi: YukkApi) {

}


ngOnInit(){
  this.urlChange()

}

urlChange(){
  this.companyName = this.childEvent;
  this.eventScore = this.childEventScore;

  this.dynamicUrl = `${window.location.origin}/widget/company?type=company&id=${this.companyName}&time=7&nomenu=true&notitle=true&custom_score=${this.eventScore}`;

}

}

