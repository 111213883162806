import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-chat-charts-trend',
  templateUrl: './chat-charts-trend.component.html',
  styleUrls: ['./chat-charts-trend.component.scss']
})
export class ChatChartsTrendComponent {

  @Input() childTrend: string;
  @Input() childTrendScore: string;

  widget = ['chart']


  score: any; 

  companyName: string;

  dynamicUrl: string;
  
  

  ngOnInit(){
    this.urlChange()
  }

  urlChange(){
    this.companyName = this.childTrend;
    this.score = this.childTrendScore;

    this.dynamicUrl = `${window.location.origin}/widget/chart?type=company&id=${this.companyName}&notitle=true&time=7&nomenu=true&custom_score=${this.score}`;

   

  }
}
